import i18n from '@/lang/i18n';
const getRangeYear = (from, to) => {
  const listYear = []
  for (let index = from; index <= to; index++) {
    listYear.push(index)
  }
  return listYear
}

export const getRangeFiscalYear = (dataSelected) => {
  const {startYear, endYear, startMonth, endMonth, startMonthSetting} = dataSelected
  const fiscalYearStart = startMonth >= startMonthSetting ? startYear : startYear - 1
  const fiscalYearEnd = endMonth >= startMonthSetting ? endYear : endYear - 1
  const rangeYear = getRangeYear(fiscalYearStart, fiscalYearEnd)
  return rangeYear
}

export const getFiscalYearList = (moment, startMonth, labelYear) => {
  let yearList = [];
  let toYear = moment().year();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const isVietnamese = i18n.locale === 'vi'
  if (startMonth > currentMonth) {
    toYear -= 1;
  }
  for (let i = toYear; i >= 2017; i--) {
    const yearVal = isVietnamese ? i18n.t('register_reduction.fiscal_year', {year: i}) : `${i}${labelYear}`
    yearList.push(yearVal);
  }
  return yearList;
}

export const getFiscalRangeFromYear = (moment, selectedYear, startMonth) => {
  const startMonthYear = moment({month: startMonth - 1, year: selectedYear}).format('YYYY-MM');
  return [startMonthYear, moment(startMonthYear).add(11, 'months').format('YYYY-MM')];
}